/** @jsx jsx */
import { useEffect, useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import ExploreLayout from '@sprinklr/website/exploreSrc/components/ExploreLayout';
import { filterDataByLocale } from '@sprinklr/shared-lib/utils';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { jsx } from 'theme-ui';
import { CardFullDisplay } from '@sprinklr/shared-lib';
import {
  productFilterOptions,
  resourceTypeFilterOptions,
  productLookup,
  resourceTypeLookup,
} from '../components/filters';
import NoLocaleContentTemplate from '../../../../src/templates/customTemplate/noLocaleContent/NoLocaleContent';
import { parseFullSlug } from '@sprinklr/shared-lib/utils/parseFullSlug';

export const resourcesIndexPageQuery = graphql`
  query (
    $language: String!
    $nodeLocale: String!
    $isHeaderV3Enabled: Boolean!
    $pageName: String! = "RESOURCES"
  ) {
    ...HeaderFooterSocialQuery
    ...MetaDataByPageName
    ...ResourcesIndexQuery
    ...ExploreSiteMetadataQuery
    ...LocalesQuery
  }
`;

const ResourcesIndexPage = ({ data, pageContext }) => {
  const {
    allContentfulExploreResourcesCard,
    allContentfulResource,
    headerV3,
    footerV3,
    allContentfulGlobalHeaderFooter,
    allContentfulMetadata,
    site,
  } = data;
  const metadata = allContentfulMetadata?.nodes[0];
  const { nodeLocale } = pageContext;

  const resources = allContentfulResource.nodes;
  const exploreResourcesEvents = allContentfulExploreResourcesCard?.nodes.map(
    node => ({ ...node }),
  );

  const localizedResourcesArray = filterDataByLocale(
    resources,
    nodeLocale,
    'allLocalesToShowCard',
  );
  const localizedResourceEventsArray = filterDataByLocale(
    exploreResourcesEvents,
    nodeLocale,
    'translations',
  );
  localizedResourceEventsArray.forEach(event => {
    if (parseFullSlug(event.slug, '/resources'))
      event.slug = parseFullSlug(event.slug, '/resources').noLocaleSlug;
  });

  const localizedResources = useMemo(
    () =>
      localizedResourcesArray
        .concat(localizedResourceEventsArray)
        .sort((a, b) => new Date(b.sortingDate) - new Date(a.sortingDate)),
    [],
  );

  const [productFilter, setProductFilter] = useState('all');
  const [typeFilter, setTypeFilter] = useState('all');
  const [filteredResources, setFilteredResources] =
    useState(localizedResources);

  const { t } = useI18next();

  useEffect(() => {
    const productFilteredResources =
      productFilter === 'all'
        ? localizedResources
        : localizedResources.filter(
            e =>
              e.relatedProducts &&
              e.relatedProducts.includes(productLookup[productFilter]),
          );
    const typeSortedResources =
      typeFilter === 'all'
        ? productFilteredResources
        : productFilteredResources.filter(
            e =>
              e.resourceType &&
              e.resourceType.includes(resourceTypeLookup[typeFilter]),
          );
    setFilteredResources(typeSortedResources);
  }, [productFilter, typeFilter]);

  const filterDetails = [
    {
      customDropdownLabel: 'Product',
      options: productFilterOptions,
      value: productFilter,
      setValue: setProductFilter,
    },
    {
      customDropdownLabel: 'Type',
      options: resourceTypeFilterOptions,
      value: typeFilter,
      setValue: setTypeFilter,
    },
  ];

  return (
    <ExploreLayout
      context={pageContext}
      slug={'/resources'}
      metadata={metadata}
      site={site}
      headerV3={headerV3}
      footerV3={footerV3}
      globalSkeletonData={allContentfulGlobalHeaderFooter?.edges[0]?.node}
    >
      <CardFullDisplay
        headline={t('Resources')}
        cards={filteredResources}
        filterDetails={filterDetails}
        overallPageType={'event'}
        cardSlugPrefix="resources"
        shouldHideNoResultsMessage={!localizedResources?.length && true}
        nodeLocale={nodeLocale}
        cardfullDisplaySx={{ pt: ['40px', 0, 0, '40px'] }}
      />
      {!localizedResources?.length && (
        <NoLocaleContentTemplate padding={{ top: 'NONE', bottom: 'LARGE' }} />
      )}
    </ExploreLayout>
  );
};

export default ResourcesIndexPage;
