import {
  getNodeLocaleFromSlug,
  removeNodeLocaleFromSlug,
} from './removeNodeLocaleFromSlug';

/* 
    Parse out a full URL into hostname, locale, and slug
    URLS must be complete and begin with https://
    Returns undefined if parsing fails or wrong type entered.
*/
export const parseFullSlug = (fullSlug: string, slugPrefix: string) => {
  if (typeof fullSlug !== 'string' || !fullSlug.startsWith('https://'))
    return undefined;

  let urlObj, fullHostname, localeSlug, nodeLocale, noLocaleSlug;
  try {
    urlObj = new URL(fullSlug);
    fullHostname = urlObj.origin;
    localeSlug = fullSlug.slice(fullHostname.length + slugPrefix.length);

    // Gets nodeLocale from localeSlug, if none, returns 'en-US' as locale.
    nodeLocale = getNodeLocaleFromSlug(localeSlug)
      ? getNodeLocaleFromSlug(localeSlug)[1]
      : 'en-US';
    noLocaleSlug = removeNodeLocaleFromSlug(localeSlug);
  } catch (error) {
    // Returns the undefined if it crashes the URL constructor.
    return undefined;
  }

  return { fullSlug, fullHostname, localeSlug, nodeLocale, noLocaleSlug };
};
